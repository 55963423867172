/** @jsx jsx */
import {useState, useEffect} from 'react';
import {jsx} from 'theme-ui';
import {Link} from 'gatsby';

import * as styles from './styles';
import {getLoweredMenuItemName, defs} from '../../utils/menuHelper';

export const HeaderMenuLink = props => {
  const [isActive, setIsActive] = useState(false);
  const {item} = props;
  const itemNameLowered = getLoweredMenuItemName(item);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsActive(
        localStorage.getItem(defs.activeMenuItem) === itemNameLowered
      );
    }
  });

  return (
    <Link
      to={!item.route ? item.menu[0].route : item.route}
      sx={styles.wrapper(isActive)}
    >
      {item.name}
    </Link>
  );
};
