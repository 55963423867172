import {media} from '~theme/breakpoints';

export const innerContainer = {
  display: 'flex',
  alignItems: 'center',

  [media.tablet]: {
    display: 'none',
  },
};
