/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React, {useRef, useState, useEffect} from 'react';
import {jsx, Container} from 'theme-ui';
import {Global} from '@emotion/core';
import {useMenus} from 'docz';
import {getLoweredMenuItemName, defs} from '../../utils/menuHelper';

import global from '~theme/global';
import {Header} from '../Header';
import {Sidebar} from '../Sidebar';
import {LowerHeader} from '../LowerHeader';
import * as styles from './styles';

export const Layout = ({children, frontmatter}) => {
  const [open, setOpen] = useState(false);
  const [hasContentToDisplay, setHasContentToDisplay] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState('');
  const nav = useRef();
  const [query] = useState('');
  const menus = useMenus({query});
  const apis = menus
    .find(menu => menu.name === 'API')
    .menu.map(menu => ({
      value: menu.route,
      label: menu.name,
      id: menu.id,
    }));
  const isErrorPage = frontmatter.isErrorPage;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setActiveMenuItem(localStorage.getItem(defs.activeMenuItem));

      const menuItem = menus.filter(
        menu => getLoweredMenuItemName(menu) === activeMenuItem
      )[0];

      if (
        menuItem !== undefined &&
        (menuItem.route === undefined || menuItem.headings.length > 1)
      ) {
        setHasContentToDisplay(true);
      }
    }
  });

  return (
    <div sx={{'& > div': {flex: '1 1 auto'}}} data-testid="layout">
      <Global styles={global} />
      <div sx={styles.main}>
        {!isErrorPage && (
          <>
            <Header onOpen={() => setOpen(s => !s)} />
            <LowerHeader
              header={frontmatter.name}
              description={frontmatter.description}
              isApiPage={frontmatter.isApiPage}
              apiName={frontmatter.apiName}
              apis={apis}
            />
          </>
        )}
        <div sx={styles.wrapper(frontmatter.isApiPage, hasContentToDisplay)}>
          {
            <Sidebar
              ref={nav}
              open={open}
              isApiPage={frontmatter.isApiPage}
              hasContentToDisplay={hasContentToDisplay}
              activeMenuItem={activeMenuItem}
              menus={menus}
              onFocus={() => setOpen(true)}
              onBlur={() => setOpen(false)}
              onClick={() => setOpen(false)}
            />
          }
          <Container
            sx={styles.content(frontmatter.isApiPage, frontmatter.isErrorPage)}
            data-testid="main-container"
          >
            {children}
          </Container>
        </div>
      </div>
    </div>
  );
};
