import axios from 'axios';
import {getBaseUrl} from '../utils/urlHelpers';
import {useEffect, useState} from 'react';

export const useVersionsEndpoint = apiName => {
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    axios
      .get(`${getBaseUrl()}/${apiName}/swagger/version`)
      .then(response => {
        setVersions(
          response.data.map(version => {
            return {
              label: version.label,
              value: version.label.toLowerCase(),
              isDeprecated: version.isDeprecated,
            };
          })
        );
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return {versions};
};
