import {media} from '~theme/breakpoints';

const ITEM_HEIGHT = '48px';

export const link = isActiveMenu => ({
  paddingLeft: '16px',
  display: isActiveMenu ? 'flex' : 'none',
  alignItems: 'center',
  color: 'sidebar.navGroup',
  textDecoration: 'none',
  fontSize: '12px',
  height: ITEM_HEIGHT,
  '&.active': {
    fontWeight: 600,
    backgroundColor: 'sidebar.tocLinkActive',
  },
  ':hover': {
    backgroundColor: 'sidebar.tocLinkHover',
  },
  '&.active:hover': {
    backgroundColor: 'sidebar.tocLinkActive',
  },
  [media.tablet]: {
    display: 'flex',
  },
});

export const smallLink = isActiveMenu => ({
  ...link(isActiveMenu),
  ml: 3,
  fontSize: '12px',
  position: 'relative',
  color: 'sidebar.tocLink',
  marginLeft: 0,
  padding: '0 32px',
  height: ITEM_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  '&.active': {
    backgroundColor: 'sidebar.tocLinkActive',
  },
  '&.active::before': {
    content: '""',
    position: 'absolute',
    display: 'block',
    top: '2px',
    left: -2,
    height: '1rem',
    backgroundColor: 'primary',
    transition: 'width 200ms ease 0s',
    width: '2px',
    borderRadius: 1,
  },
  ':hover': {
    backgroundColor: 'sidebar.tocLinkHover',
  },
  '&.active:hover': {
    backgroundColor: 'sidebar.tocLinkActive',
  },
  [media.tablet]: {
    display: 'none',
  },
});
