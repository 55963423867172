/** @jsx jsx */
import {jsx, Box} from 'theme-ui';

import * as styles from './styles';
import {Menu} from '../Icons';
import {Logo} from '../Logo';
import {HeaderMenu} from '../HeaderMenu';

export const Header = props => {
  const {onOpen} = props;

  return (
    <div sx={styles.wrapper} data-testid="header">
      <div sx={styles.innerContainer}>
        <Logo />
        <HeaderMenu />
      </div>
      <a sx={styles.link} href="https://www.planday.com">
        Visit our website
      </a>
      <Box sx={styles.menuIcon}>
        <button sx={styles.menuButton} onClick={onOpen}>
          <Menu size={25} />
        </button>
      </Box>
    </div>
  );
};
