import {media} from '~theme/breakpoints';

export const main = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 50px)',
};

export const wrapper = (isApiPage, hasContentToDisplay) => ({
  py: 0,
  display: 'flex',
  minHeight: 'inherit',
  [media.tablet]: {
    display: 'block',
  },
});

export const sidebarWrapper = {
  minWidth: '256px',
  minHeight: '100vh',
};

export const content = (isApiPage, isErrorPage) => ({
  backgroundColor: '#fff',
  position: 'relative',
  py: 5,
  px: 4,
  variant: 'styles.Container',
  marginLeft: isApiPage || isErrorPage ? 0 : 'auto',
  padding: isApiPage || isErrorPage ? 0 : '16px',
  width: '100%',
  'h1:not(:first-of-type)': {
    paddingTop: '75px',
  },
  [media.tablet]: {
    py: 4,
    px: 4,
    pt: 5,
  },
});
