export const logo = {
  letterSpacing: '-0.02em',
  fontWeight: 600,
  flex: 1,
  alignItems: 'center',
  marginRight: 12,
};

export const link = {
  fontWeight: 600,
  margin: '0 0 0 5px',
  color: 'header.text',
  textDecoration: 'none',
  ':hover': {
    color: 'primary',
  },
};
