import {media} from '~theme/breakpoints';

const ITEM_HEIGHT = '48px';

export const wrapper = isActiveMenu => ({
  marginBottom: '4px',
  display: isActiveMenu ? 'block' : 'none',

  [media.tablet]: {
    display: 'block',
  },
});

export const title = isActiveMenu => ({
  mb: 1,
  fontSize: '12px',
  fontWeight: 500,
  color: 'sidebar.navGroup',
  cursor: 'pointer',
  display: isActiveMenu ? 'none' : 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: ITEM_HEIGHT,

  [media.tablet]: {
    display: 'flex',
    padding: '0 8px',
  },
});

export const chevron = ({active}) => ({
  ml: 1,
  flexShrink: 0,
  transform: `rotateX(${active ? 180 : 0}deg)`,
  transformOrigin: 'center',
  transition: 'transform .3s ease-in-out',
});

export const navGroup = () => ({
  paddingRight: '1rem',
});
