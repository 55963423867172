import * as colors from '../../theme/colors';

export const container = {
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${colors.gray20}`,
  backgroundColor: '#fff',
};

export const dropdownContainer = {
  display: 'flex',
  gap: '16px',
  marginBottom: '15px',
  button: {
    width: '100%',
  },
};

export const contentItem = {
  margin: 0,
};

export const header = {
  ...contentItem,
  marginBottom: '16px',
  fontWeight: 600,
};

export const description = {
  ...contentItem,
  fontSize: '12px',
};

export const dropdown = {
  fontSize: '14px',
  width: '240px',
  '.select__control': {
    height: '32px',
    border: `1px solid ${colors.grayLight}`,
    borderRadius: '4px',
    marginBottom: '16px',
  },
  '.select__indicator-separator': {
    display: 'none',
  },
  '.select__menu': {
    top: '32px',
    backgroundColor: '#fff',
    zIndex: 9000,
  },
};
