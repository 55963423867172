/** @jsx jsx */
import {useCurrentDoc} from 'docz';
import React from 'react';
import {jsx} from 'theme-ui';
import {ChevronDown} from '../Icons';
import {NavLink} from '../NavLink';
import * as styles from './styles';

export const NavGroup = ({item, sidebarRef, isActiveMenu}) => {
  const currentDoc = useCurrentDoc();
  const currentDocRef = React.useRef();
  const {name, menu} = item;
  const [subheadingsVisible, setShowsubheadings] = React.useState(
    currentDoc.menu === name
  );
  const toggleSubheadings = () => setShowsubheadings(!subheadingsVisible);
  React.useEffect(() => {
    if (sidebarRef.current && currentDocRef.current) {
      sidebarRef.current.scrollTo(0, currentDocRef.current.offsetTop);
    }
  }, []);
  return (
    <div sx={styles.wrapper(isActiveMenu)} data-testid="nav-group">
      <div sx={styles.title} onClick={toggleSubheadings}>
        {item.name}
        <ChevronDown sx={styles.chevron({active: subheadingsVisible})} />
      </div>
      <div data-testid="nav-group-links" sx={styles.navGroup}>
        {menu &&
          subheadingsVisible &&
          menu.map(menu => {
            if (currentDoc.route === menu.route) {
              return (
                <NavLink
                  key={menu.id}
                  item={menu}
                  ref={currentDocRef}
                  isActiveMenu={isActiveMenu}
                >
                  {menu.name}
                </NavLink>
              );
            }
            return (
              <NavLink key={menu.id} item={menu} isActiveMenu={isActiveMenu}>
                {menu.name}
              </NavLink>
            );
          })}
      </div>
    </div>
  );
};
