/** @jsx jsx */
import {useState} from 'react';
import {jsx, Box} from 'theme-ui';
import {useMenus, useCurrentDoc} from 'docz';

import * as styles from './styles';

import {HeaderMenuLink} from '../HeaderMenuLink';

export const HeaderMenu = () => {
  const [query] = useState('');
  const menus = useMenus({query});
  const currentDoc = useCurrentDoc();

  return (
    <Box sx={styles.innerContainer} data-testid="navmenu">
      {menus &&
        menus.map(menu => {
          if (menu.hideInNavigation !== true) {
            return (
              <HeaderMenuLink
                key={menu.id}
                item={menu}
                currentDoc={currentDoc}
              />
            );
          }
        })}
    </Box>
  );
};
