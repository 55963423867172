/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React, {useRef, useEffect} from 'react';
import {Global} from '@emotion/core';
import {jsx, Box} from 'theme-ui';
import {useCurrentDoc} from 'docz';
import {getLoweredMenuItemName} from '../../utils/menuHelper';

import * as styles from './styles';
import {NavLink} from '../NavLink';
import {NavGroup} from '../NavGroup';

export const Sidebar = React.forwardRef((props, ref) => {
  const {menus, hasContentToDisplay, isApiPage} = props;
  const currentDoc = useCurrentDoc();
  const currentDocRef = useRef();

  useEffect(() => {
    if (ref.current && currentDocRef.current) {
      ref.current.scrollTo(0, currentDocRef.current.offsetTop);
    }
  }, []);
  return (
    <>
      <Box onClick={props.onClick} sx={styles.overlay(props)}>
        {props.open && <Global styles={styles.global} />}
      </Box>
      <Box
        ref={ref}
        sx={!props.isApiPage ? styles.wrapper(props) : styles.nowrapper(props)}
        data-testid="sidebar"
      >
        {menus &&
          menus.map(menu => {
            const itemNameLowered = getLoweredMenuItemName(menu);
            const isActiveMenu = itemNameLowered === props.activeMenuItem;

            if (menu.hideInNavigation) {
              return;
            }
            if (!menu.route)
              return (
                <NavGroup
                  key={menu.id}
                  item={menu}
                  sidebarRef={ref}
                  isActiveMenu={isActiveMenu}
                />
              );
            if (menu.route === currentDoc.route) {
              return (
                <NavLink
                  key={menu.id}
                  item={menu}
                  ref={currentDocRef}
                  isActiveMenu={isActiveMenu}
                >
                  {menu.name}
                </NavLink>
              );
            }
            return (
              <NavLink key={menu.id} item={menu} isActiveMenu={isActiveMenu}>
                {menu.name}
              </NavLink>
            );
          })}
      </Box>
    </>
  );
});
