import * as colors from '../../theme/colors';

export const wrapper = isActive => ({
  padding: '0 12px 0 12px',
  color: 'header.text',
  textDecoration: 'none',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: isActive ? colors.grayMenuHover : 'transparent',
  ':hover': {
    backgroundColor: colors.grayMenuHover,
  },
});
