import * as colors from './colors';
import prismLight from './prism/light';

export const light = {
  ...colors,
  text: colors.grayDark,
  muted: colors.gray,
  link: colors.blue,
  background: colors.white,
  border: colors.grayLight,
  sidebar: {
    bg: colors.gray10,
    navGroup: colors.grayDark,
    navLink: colors.grayDark,
    navLinkActive: colors.blue,
    tocLink: colors.black,
    tocLinkActive: colors.blue10,
    tocLinkHover: colors.gray20,
  },
  header: {
    bg: colors.plandayDarkBlue,
    text: colors.white,
    button: {
      bg: colors.blue,
      color: colors.white,
    },
  },
  props: {
    bg: colors.grayUltraLight,
    text: colors.grayDark,
    highlight: colors.blue,
    defaultValue: colors.gray,
    descriptionText: colors.grayDark,
    descriptionBg: colors.white,
  },
  playground: {
    bg: colors.white,
  },
  blockquote: {
    bg: colors.grayExtraLight,
    border: colors.grayLight,
    color: colors.gray,
  },
  prism: {
    ...prismLight,
  },
};
