import 'babel-polyfill';
import * as React from 'react';
import {Helmet} from 'react-helmet-async';

const Wrapper = ({children}) => (
  <React.Fragment>
    <Helmet>
      <meta charSet="utf-8" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="https://cdn.marketing.planday.com/website/favicon/apple-touch-icon.png"
      />
      <link
        rel="shortcut icon"
        type="image/png"
        sizes="48x48"
        href="https://cdn.marketing.planday.com/website/favicon/favicon-48x48.png"
      />
      <link
        rel="icon"
        href="https://cdn.marketing.planday.com/website/favicon/favicon.ico"
      />
      <link
        rel="manifest"
        href="https://cdn.marketing.planday.com/website/favicon/site.webmanifest"
      />
      <link
        rel="mask-icon"
        href="https://cdn.marketing.planday.com/website/favicon/safari-pinned-tab.svg"
        color="#199bd7"
      />
      <meta name="msapplication-TileColor" content="#2d89ef" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
    {children}
  </React.Fragment>
);
export default Wrapper;
