/** @jsx jsx */
import {jsx, Flex} from 'theme-ui';
import {Link, useConfig} from 'docz';

import * as styles from './styles';

export const Logo = () => {
  const config = useConfig();
  return (
    <Flex sx={styles.logo} data-testid="logo">
      <svg
        width="30"
        height="24"
        viewBox="0 0 30 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9977 10.3924C21.6739 8.85991 20.8768 7.41102 19.5948 6.28993C16.4623 3.55079 12.0198 4.09258 9.51432 7.20419L9.51417 7.20435V7.2045H9.51402L0.413587 18.507C-0.176843 19.2402 -0.130885 20.0561 0.524384 20.6288C1.17951 21.2017 1.95831 21.1073 2.54874 20.3741L11.6243 9.10258L11.625 9.10319L11.6493 9.07162C11.6988 9.01 11.7494 8.95051 11.801 8.8924L11.8264 8.86464C13.4893 7.01963 16.1055 6.98623 17.8241 8.48911C17.8241 8.48911 17.8345 8.49567 17.8537 8.50695C19.8726 10.2975 19.7653 13.1648 18.3462 14.9273C18.2966 14.9889 18.1774 15.1248 18.1691 15.1342C16.5061 16.9792 13.9295 17.0189 12.1418 15.4919C11.7686 15.1609 11.4681 14.793 11.2353 14.4022C10.956 13.9335 10.3193 13.8862 9.97961 14.3094L8.81236 15.7625C9.21588 16.4753 9.74455 17.1351 10.4007 17.7088C13.5332 20.4481 17.9757 19.9063 20.4812 16.7945C20.4812 16.7945 22.4836 14.3746 21.9977 10.3924Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0011 19.4797C15.0009 19.4797 15.0009 19.4797 15.0007 19.4797C15.0009 19.4797 15.0009 19.4797 15.0011 19.4797ZM10.4247 17.7298C11.7924 18.9158 13.407 19.4795 15.0004 19.4797C13.4072 19.4794 11.7922 18.9156 10.4247 17.7298ZM10.406 17.7135C10.4088 17.7158 10.4111 17.7179 10.4139 17.7203C10.4111 17.7179 10.4088 17.7158 10.406 17.7135ZM20.4752 16.8018C20.4732 16.8046 20.471 16.8073 20.4688 16.8099C20.471 16.8073 20.4732 16.8046 20.4752 16.8018ZM9.98696 14.3002C9.98506 14.3025 9.98331 14.3046 9.98157 14.3068C9.98331 14.3046 9.98506 14.3025 9.98696 14.3002ZM10.5748 14.0192C10.8305 14.0194 11.0849 14.1496 11.2353 14.4022C11.4682 14.793 11.7687 15.1609 12.1419 15.4919C12.983 16.2103 13.9985 16.5819 15.0116 16.5819C16.1518 16.5819 17.2886 16.111 18.1691 15.1342C18.1775 15.1248 18.2966 14.9889 18.3462 14.9272C18.3678 14.9005 18.3888 14.8737 18.4098 14.8466L18.3435 14.9288C18.307 14.9739 18.1686 15.1332 18.1664 15.1356C17.2864 16.1118 16.1492 16.5808 15.0102 16.5808C13.9966 16.5808 12.9819 16.2093 12.1464 15.4931C11.7732 15.162 11.4682 14.793 11.2353 14.4022C11.0847 14.1496 10.8305 14.0194 10.5748 14.0192ZM10.5743 14.0192C10.5742 14.0192 10.574 14.0192 10.574 14.0192C10.574 14.0192 10.5742 14.0192 10.5743 14.0192ZM28.58 3C28.1811 3 27.7859 3.21034 27.4514 3.62598L22.0022 10.3934L21.9984 10.3983C22.4617 14.2116 20.6433 16.5907 20.4912 16.7822L29.5865 5.49311C30.1769 4.75974 30.1308 3.94401 29.4758 3.37111C29.1919 3.12279 28.885 2.99985 28.58 3Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9984 10.3983L18.4097 14.8466C18.3888 14.8737 18.3677 14.9006 18.3462 14.9273C18.2966 14.9889 18.1774 15.1248 18.1691 15.1343C17.2886 16.1111 16.1518 16.5819 15.0116 16.5819C13.9985 16.5819 12.983 16.2104 12.1419 15.4919C11.7686 15.1609 11.4681 14.793 11.2353 14.4023C11.0848 14.1497 10.8304 14.0194 10.5747 14.0193C10.5746 14.0193 10.5744 14.0193 10.5743 14.0193C10.5742 14.0193 10.574 14.0193 10.574 14.0193C10.3606 14.0194 10.1463 14.11 9.9906 14.2958C9.98943 14.2973 9.98812 14.2987 9.98694 14.3002C9.98504 14.3025 9.98329 14.3046 9.98153 14.3069C9.9808 14.3077 9.98021 14.3085 9.97963 14.3094L8.81238 15.7625C9.2159 16.4753 9.74457 17.1351 10.4007 17.7088C10.4023 17.7103 10.4044 17.712 10.406 17.7135C10.4088 17.7158 10.4111 17.718 10.4139 17.7204C10.4174 17.7235 10.4211 17.7267 10.4247 17.7299C11.7922 18.9156 13.4072 19.4794 15.0003 19.4797C15.0005 19.4797 15.0005 19.4797 15.0006 19.4797C15.0009 19.4797 15.0009 19.4797 15.0011 19.4797C17.049 19.4792 19.0622 18.5477 20.4688 16.81C20.471 16.8074 20.4731 16.8046 20.4752 16.8019C20.4772 16.7994 20.4791 16.7971 20.4812 16.7945L20.4912 16.7822C20.6432 16.5908 22.4616 14.2116 21.9984 10.3983Z"
          fill="white"
        />
      </svg>
      <Link to="/" sx={styles.link}>
        {config.title}
      </Link>
    </Flex>
  );
};
