import React from 'react';
import styles from './styles.module.css';

export const markdowns = {
  p: props => <p {...props} className={styles.p} />,
  h1: props => <h1 {...props} className={styles.h1} />,
  h2: props => <h2 {...props} className={styles.h2} />,
  h3: props => <h3 {...props} className={styles.h3} />,
  h4: props => <h4 {...props} className={styles.h4} />,
  h5: props => <h5 {...props} className={styles.h5} />,
  h6: props => <h6 {...props} className={styles.h6} />,
  blockquote: props => <blockquote {...props} className={styles.blockquote} />,
  ul: props => <ul {...props} className={styles.ul} />,
  ol: props => <ol {...props} className={styles.ol} />,
  li: props => <li {...props} className={styles.li} />,
  table: props => <table {...props} className={styles.table} />,
  tr: props => <tr {...props} className={styles.tr} />,
  td: props => <td {...props} className={styles.td} />,
  th: props => <th {...props} className={styles.th} />,
  pre: props => <pre {...props} className={styles.pre} />,
  code: props => <code {...props} className={styles.code} />,
  inlineCode: props => <code {...props} className={styles.code} />,
  em: props => <em {...props} className={styles.em} />,
  strong: props => <strong {...props} className={styles.strong} />,
  hr: props => <hr {...props} className={styles.hr} />,
  thematicBreak: props => <hr {...props} className={styles.hr} />,
  a: props => <a {...props} className={styles.a} />,
  img: props => <img {...props} className={styles.img} />,
};
