import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';

import {useDbQuery} from '../hooks/useDbQuery';

const SEO = ({description, lang, meta, keywords, title: initialTitle}) => {
  const db = useDbQuery();
  const title = initialTitle || db.config.title;
  description = 'Planday Developer | API Documentation';
  const metaDescription = description || db.config.description;

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${db.config.title}`}
      htmlAttributes={{lang}}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
