/** @jsx jsx */
import {jsx, Box} from 'theme-ui';
import * as styles from './styles';
import Select from 'react-select';
import {useEffect} from 'react';
import {useVersionsEndpoint} from '../../hooks/useVersionsEndpoint';

const changeApiVersion = apiVersion => {
  window.location = `${window.location.origin}${window.location.pathname}?version=${apiVersion}`;
};

const getCurrentApiVersion = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('version');
};

export const LowerHeader = ({
  header,
  description,
  isApiPage,
  apis,
  apiName,
}) => {
  const {versions} = useVersionsEndpoint(apiName);

  useEffect(() => {
    if (getCurrentApiVersion() || !versions.length) {
      return;
    }
    changeApiVersion(versions[0].value);
  }, [versions]);

  if (!header || !description || !isApiPage) {
    return null;
  }

  const transformedVersions = () =>
    versions.map(item => ({
      ...item,
      label: item.isDeprecated ? `${item.label} (Deprecated)` : item.label,
    }));

  return (
    <Box sx={styles.container}>
      <div sx={styles.dropdownContainer}>
        <Select
          sx={styles.dropdown}
          classNamePrefix="select"
          value={apis.find(apiItem => apiItem.label === header)}
          onChange={option => (window.location = option.value)}
          options={apis}
        />
        {versions.length > 0 && (
          <Select
            sx={styles.dropdown}
            classNamePrefix="select"
            value={transformedVersions().find(
              apiVersion => apiVersion.value == getCurrentApiVersion()
            )}
            onChange={option => changeApiVersion(option.value)}
            options={transformedVersions()}
          />
        )}
      </div>
      <p sx={styles.description}>{description}</p>
    </Box>
  );
};
