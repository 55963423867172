import * as mixins from '~utils/mixins';
import {media} from '~theme/breakpoints';
import * as colors from '../../theme/colors';

export const wrapper = {
  bg: 'header.bg',
  position: 'sticky',
  top: 0,
  zIndex: 2,
  borderBottom: t => `1px solid ${t.colors.border}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '12px',
  color: 'header.text',
};

export const innerContainer = {
  display: 'flex',
  padding: '0 16px',
  position: 'relative',
  height: '48px',
};

export const menuIcon = {
  display: 'none',
  [media.tablet]: {
    display: 'block',
  },
};

export const menuButton = {
  ...mixins.ghostButton,
  color: 'header.text',
  opacity: 0.5,
  cursor: 'pointer',
  padding: '0 16px',
};

export const headerButton = {
  ...mixins.centerAlign,
  outline: 'none',
  p: '12px',
  border: 'none',
  borderRadius: 9999,
  bg: 'header.button.bg',
  color: 'header.button.color',
  fontSize: 0,
  fontWeight: 600,
  cursor: 'pointer',
};

export const editButton = {
  ...mixins.centerAlign,
  position: 'absolute',
  bottom: -40,
  right: 30,
  bg: 'transparent',
  color: 'muted',
  fontSize: 1,
  textDecoration: 'none',
  borderRadius: 'radius',
};

export const link = {
  color: colors.plandayLightBlue,
  textDecoration: 'none',
  padding: '0 16px',
  ':hover': {
    textDecoration: 'underline',
  },
  [media.tablet]: {
    display: 'none',
  },
};
